export default function numberFormatter(number) {
  if (typeof number !== "Number") {
    number = Number(number);
  }

  return number.toLocaleString("es-ES", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
}
